<template>
  <div class="technique_process main_padding" ref="technique_process">
    <div class="technique_outer w1400">

      <div class="technique_nav">
        <template
          v-for="(item, i) in techniqueProcessData">
            <div :key="`techniqueNav${i}`">
              <router-link
                :to="{
                  name: 'TechniqueProcess',
                  query: {
                    index: i+1,
                  },
                }"
                class="technique_nav_item">
                  <img
                    class="icon"
                    src="@/assets/img/process/03_technique_process/icon1.svg" alt="">
                  <small>{{item.name}}</small>
                  <img
                    class="arrow"
                    src="@/assets/img/public/arrow-r.svg"
                    alt="">
              </router-link>
              <div class="technique_subnav">
                <template v-for="(subdata, i) in item.data">
                  <div
                    class="technique_subnav_item"
                    @click="scrollTo($refs.techniqueItems[i].$el);"
                    :key="`techniqueSubnavItem${item.name}${i}`">
                      <small>{{subdata.title}}</small>
                  </div>
                </template>
              </div>
            </div>
        </template>
      </div>

      <div class="technique_infobox">
        <TechniqueItem
          v-for="(item, i) in nowData"
          :key="`techniqueItem${i}`"
          :ref="'techniqueItems'"
          :itemData="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import { techniqueProcessData } from '@/utils/processPageData';
import TechniqueItem from '@/components/page/process/TechniqueItem.vue';

export default {
  name: 'TechniqueProcess',
  // props: {
  //   classificationIndex: {
  //     type: [Number, String],
  //     default: '1',
  //   },
  // },
  components: {
    TechniqueItem,
  },
  data() {
    return {
      techniqueProcessData,
    };
  },
  watch: {
    $route(to, form) {
      console.log(to, form);
      window.scrollTo({
        top: this.$refs.technique_process.offsetTop,
        behavior: 'smooth',
      });
    },
  },
  computed: {
    nowIndex() {
      return this.$route.query.index ? Number(this.$route.query.index) : 1;
    },
    nowData() {
      let temp = null;
      console.log(this.nowIndex, techniqueProcessData.length);
      if (!(this.nowIndex > techniqueProcessData.length)) {
        temp = techniqueProcessData[this.nowIndex - 1].data;
      } else {
        temp = null;
      }
      return temp;
    },
  },
  methods: {
    scrollTo(elem) {
      console.log(elem, window);
      window.scrollTo({
        top: elem.offsetTop - 50,
        behavior: 'smooth',
      });
    },
  },
  created() {
    if (
      !this.$route.query.index
      || this.$route.query.index > techniqueProcessData.length
      || this.$route.query.index <= 0
      // eslint-disable-next-line no-restricted-globals
      || isNaN(Number(this.$route.query.index))
    ) {
      this.$router.push(
        {
          name: 'TechniqueProcess',
          query: {
            index: 1,
          },
        },
      );
    }
  },
};
</script>
