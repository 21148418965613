<template>
  <div class="technique_item">
    <!-- title 跟 介紹 -->
    <h5 class="sub_title txt-bold">
      {{itemData.title}}
    </h5>
    <h6 class="txt-bold">
      {{itemData.subTitle}}
    </h6>
    <p>
      {{itemData.info}}
    </p>

    <template v-for="(moduleItem, i) in itemData.modules">

      <div :key="`techniqueItemModules${i}-${moduleItem.type}`">
        <!-- 一般的list -->
        <!-- <ul
          v-if="moduleItem.type==='list'"
          class="info_list">
            <li
              v-for="(item, i) in moduleItem.list"
              :key="`infoList${i}`">
              <p>
                {{item}}
              </p>
            </li>
        </ul> -->

        <ul class="info_list" v-if="moduleItem.type === 'list'">
          <template
            v-for="(item, i) in moduleItem.list">
              <li
                :key="`infoList${i}`"
                v-if="typeof item === 'object'">
                  <p>
                    <!-- <span class="txt-theme">・</span> -->
                    <span class="txt-bold" style="display: inline-block; margin-bottom: 5px;">
                      {{item.title}}
                    </span>
                    <br>
                    <span v-html="item.text">
                    </span>
                  </p>
              </li>
              <li
                :key="`infoList${i}`"
                v-else>
                  <p>
                    <!-- <span class="txt-theme">・</span> -->
                    {{item}}
                  </p>
              </li>
          </template>
        </ul>

        <!-- 大項目的list -->
        <ul
          v-if="moduleItem.type==='topPoints'"
          class="info_list main_list">
            <li
              v-for="(item, i) in moduleItem.list"
              :key="`topPoint${i}`">
              <p>
                <!-- <span class="txt-theme">・</span> -->
                {{item}}
              </p>
            </li>
        </ul>

        <!-- 圖片及圖片說明 -->
        <template v-if="moduleItem.type==='pic'">
          <div class="pic_outer">
            <p
              v-if="moduleItem.title"
              class="pic_title txt-bold">
                <span>
                  {{moduleItem.title}}
                </span>
            </p>

            <p
              v-if="moduleItem.info"
              v-html="moduleItem.info">
            </p>

            <template v-if="moduleItem.url">
              <img
                v-if="
                  !Array.isArray(moduleItem.url)
                  || moduleItem.url.length < 1
                "
                :src="moduleItem.url"
                class="pic"
                alt="">

              <template v-else>
                <img
                  v-for="(item, k) in moduleItem.url"
                  :key="`togglePic${i}${k}`"
                  :src="item"
                  class="pic"
                  alt="">
              </template>
            </template>
          </div>
        </template>

        <!-- 具體活動list -->
        <template v-if="moduleItem.type==='specific'">
          <h6 class="redline_title txt-theme txt-bold txt-center">
            具體活動
          </h6>
          <ul class="info_list">
            <li
              v-for="(item, i) in moduleItem.list"
              :key="`specificPoint${i}`">
              <p>
                <span class="txt-theme">・</span>
                {{item}}
              </p>
            </li>
          </ul>
        </template>

        <h6
          class="txt-bold txt-theme"
          v-if="moduleItem.type==='title'">
            {{moduleItem.title}}
        </h6>

        <!-- 左右切換list -->
        <!-- <template v-if="moduleItem.type==='toggle'">
          <div class="technique_tabs">
            <div
              class="tab"
              :class="[{'active': (tabNowIndex == 0)}]"
              @click="() => {tabNowIndex = 0}"
              v-if="moduleItem.lists[0]">
                <p>{{moduleItem.lists[0].name}}</p>
            </div>
            <div
              class="tab"
              :class="[{'active': (tabNowIndex == 1)}]"
              @click="() => {tabNowIndex = 1}"
              v-if="moduleItem.lists[1]">
                <p>{{moduleItem.lists[1].name}}</p>
            </div>
          </div>
          <ul class="info_list">
            <li
              v-for="(item, i) in moduleItem.lists[tabNowIndex].list"
              :key="`infoList${i}`">
              <p>
                <span class="txt-theme">・</span>
                {{item}}
              </p>
            </li>
          </ul>
        </template> -->

        <!-- 左右切換 -->
        <template v-if="moduleItem.type==='toggle'">
          <div class="technique_tabs">
            <div
              class="tab"
              :class="[{'active': (tabNowIndex == 0)}]"
              @click="() => {tabNowIndex = 0}"
              v-if="moduleItem.lists[0]">
                <p>{{moduleItem.lists[0].name}}</p>
            </div>
            <div
              class="tab"
              :class="[{'active': (tabNowIndex == 1)}]"
              @click="() => {tabNowIndex = 1}"
              v-if="moduleItem.lists[1]">
                <p>{{moduleItem.lists[1].name}}</p>
            </div>
          </div>

          <p v-if="moduleItem.lists[tabNowIndex].info">
            {{moduleItem.lists[tabNowIndex].info}}
          </p>

          <ul class="info_list" v-if="moduleItem.lists[tabNowIndex].list">
            <template
              v-for="(item, i) in moduleItem.lists[tabNowIndex].list">
                <li
                  :key="`infoList${i}`"
                  v-if="typeof item === 'object'">
                    <p>
                      <!-- <span class="txt-theme">・</span> -->
                      <span class="txt-bold" style="display: inline-block; margin-bottom: 5px;">
                        {{item.title}}
                      </span>
                      <br>
                      <span v-html="item.text">
                      </span>
                    </p>
                </li>
                <li
                  :key="`infoList${i}`"
                  v-else>
                    <p>
                      <!-- <span class="txt-theme">・</span> -->
                      {{item}}
                    </p>
                </li>
            </template>
          </ul>

          <!-- <div class="pic_outer"> -->
          <template v-if="moduleItem.lists[tabNowIndex].url">
            <img
              v-if="
                !Array.isArray(moduleItem.lists[tabNowIndex].url)
                || moduleItem.lists[tabNowIndex].url.length < 1
              "
              :src="moduleItem.lists[tabNowIndex].url"
              class="pic"
              alt="">

            <template v-else>
              <img
                v-for="(item, k) in moduleItem.lists[tabNowIndex].url"
                :key="`togglePic${i}${k}`"
                :src="item"
                class="pic"
                alt="">
            </template>
          </template>
          <!-- </div> -->

        </template>

        <!-- 純文字 -->
        <div class="content" v-if="moduleItem.type==='content'">
          <p v-html="moduleItem.info">
          </p>
        </div>
      </div>

    </template>

  </div>
</template>

<script>

export default {
  name: 'TechniqueItem',
  props: ['itemData'],
  data() {
    return {
      tabNowIndex: 0,
    };
  },
  watch: {
    $route() {
      this.tabNowIndex = 0;
    },
  },
};
</script>
